import dayjs from "dayjs";
import LocalizedFormat from "dayjs/plugin/localizedFormat";
import UTC from "dayjs/plugin/utc";
import Timezone from "dayjs/plugin/timezone";
import "dayjs/locale/en-gb";

dayjs.locale("en-gb"); // TODO: Load from user prefs else use default locale en
dayjs.extend(LocalizedFormat);
dayjs.extend(UTC);
dayjs.extend(Timezone);

const timezoneInfo = {
  getName: () => {
    return (
      JSON.parse(localStorage.getItem("prefs.user.timezone") ?? "") ??
      dayjs.tz.guess()
    );
  },
  getOffsetName: (timezone?: string) => {
    let currentTimezone = timezone;

    if (timezone === null || timezone === undefined) {
      currentTimezone =
        JSON.parse(localStorage.getItem("prefs.user.timezone") ?? "") ??
        dayjs.tz.guess();
    }

    return dayjs.tz("2014-06-01 12:00", currentTimezone).offsetName("short");
  },
};

export { timezoneInfo };

export default function Dates(
  date?: string | number | dayjs.Dayjs | Date | null | undefined,
  useDefaultTimezone?: boolean,
  keepInputTime?: boolean
) {
  if (date && useDefaultTimezone) {
    return dayjs(date).tz(timezoneInfo.getName(), keepInputTime);
  } else if ((date === null || date === undefined) && useDefaultTimezone) {
    return dayjs().tz(timezoneInfo.getName(), keepInputTime);
  } else {
    return dayjs(date);
  }
}
