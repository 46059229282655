import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import {
  ClipboardIcon,
  CheckIcon,
  CalendarDaysIcon,
  ArrowTopRightOnSquareIcon as LinkIcon,
} from "@heroicons/react/24/outline";

import API from "../helpers/api";
import Page from "../components/page";
import { FormField } from "./../components/pageDetails";
import SectionDetails from "../components/sectionDetails";
import { useAuth } from "../hooks/auth";
import { timezoneInfo } from "../helpers/dates";

export default function Settings() {
  const { user, onLogin } = useAuth();

  const [loadingUser, setLoadingUser] = useState<boolean>(false);
  const [userName, setUserName] = useState(user?.name ?? "");
  const [userEmail, setUserEmail] = useState(user?.email ?? "");

  const [loadingCalendars, setLoadingCalendars] = useState<boolean>(true);
  const [calendars, setCalendars] = useState<any>({ list: [] });

  const [timezoneValue, setTimezoneValue] = useState<any>(
    user
      ? {
          value: user.timezone.replace(/_/g, " "),
          label: `${user.timezone} (${timezoneInfo.getOffsetName(
            user.timezone
          )})`,
        }
      : null
  );
  const [timezoneOptions, setTimezoneOptions] = useState<any>({
    list: [],
  });
  const [loadingTimezones, setLoadingTimezones] = useState<boolean>(false);

  useEffect(() => {
    // TODO: Move to useSWR hook
    const controller = new AbortController();
    const signal = controller.signal;

    setLoadingCalendars(true);

    API.calendars
      .list(1, signal)
      .then((res: any) => {
        setCalendars({ list: res.data });
        setLoadingCalendars(false);
      })
      .catch(() => {
        if (!signal.aborted) toast.error("Failed to load calendar settings");
      });

    setLoadingTimezones(true);

    API.user
      .getTimezones(signal)
      .then((res: any) => {
        const tempTimezones: {
          value: string;
          label: string;
          disabled?: boolean;
        }[] = [];

        res.forEach((timezone: string) => {
          tempTimezones.push({
            value: timezone,
            label: `${timezone.replace(
              /_/g,
              " "
            )} (${timezoneInfo.getOffsetName(timezone)})`,
          });
        });

        setTimezoneOptions({ list: tempTimezones });
        setLoadingTimezones(false);
      })
      .catch(() => {
        if (!signal.aborted) toast.error("Failed to load timezones");

        setLoadingTimezones(false);
      });

    return () => {
      controller.abort();
    };
  }, []);

  const onSaveChanges = () => {
    if (timezoneValue === null) {
      toast.error("Default Timezone is required");
      return;
    }

    setLoadingUser(true);

    // TODO: Only send changed fields
    const userDetails = {
      name: userName,
      timezone: timezoneValue?.value,
    };

    API.user
      .update(userDetails)
      .then((data) => {
        onLogin(data);
        toast.success("Details updated successfully");
        setLoadingUser(false);
      })
      .catch(() => {
        toast.error("Failed to update details");
        setLoadingUser(false);
      });
  };

  const [copiedURL, setCopiedURL] = useState(false);
  const onCopyCalendarURL = () => {
    navigator.clipboard.writeText(calendars.list[0]?.webcal_url);
    setCopiedURL(true);

    toast.success("Copied to clipboard");
  };

  const onOpenCalendarURL = (service: string) => {
    let redirectTo = "";

    if (service === "google") {
      redirectTo = `https://www.google.com/calendar/render?cid=${calendars.list[0]?.webcal_url}`;
    } else {
      redirectTo = calendars.list[0]?.webcal_url;
    }

    window.open(redirectTo, "_blank");
  };

  return (
    <Page title="Settings">
      <SectionDetails
        title="My Account"
        onSave={onSaveChanges}
        loading={loadingUser}
      >
        <FormField
          id="user_name"
          title="Name"
          type="text"
          value={userName}
          onChange={(e: any) => setUserName(e.target.value)}
          required={true}
        />
        <FormField
          id="user_email"
          title="Email address"
          type="email"
          value={userEmail}
          onChange={(e: any) => setUserEmail(e.target.value)}
          required={true}
          disabled={true}
        />
        <FormField
          id="user_timezone"
          title="Default Timezone"
          type="searchable"
          value={timezoneValue}
          onChange={setTimezoneValue}
          selectConfig={{
            options: timezoneOptions.list,
            loading: loadingTimezones,
          }}
          required={true}
        />
      </SectionDetails>
      <SectionDetails title="Calendar Subscription">
        <FormField
          id="calendar_subscribe"
          title="Calendar URL"
          type="text"
          value={calendars.list[0]?.webcal_url ?? ""}
          readOnly={true}
          footer={
            <>
              {!loadingCalendars && calendars.list.length > 0 && (
                <div className="flex mt-3 mb-5 sm:flex-row flex-col sm:space-x-2 sm:space-y-0 space-x-0 space-y-2">
                  <button
                    onClick={() => onCopyCalendarURL()}
                    type="button"
                    className="py-3 px-4 group inline-flex items-center gap-x-2 text-sm font-medium rounded-lg border border-gray-200 bg-white text-gray-800 shadow-sm hover:bg-gray-50 disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-900 dark:border-neutral-700 dark:text-white dark:hover:bg-neutral-800"
                  >
                    {copiedURL ? (
                      <CheckIcon className="h-5 w-5 text-blue-600" />
                    ) : (
                      <ClipboardIcon className="h-5 w-5" />
                    )}
                    <span>{copiedURL ? "Copied" : "Copy"}</span>
                  </button>
                  <button
                    onClick={() => onOpenCalendarURL("google")}
                    type="button"
                    className="py-3 px-4 group inline-flex items-center gap-x-2 text-sm font-medium rounded-lg border border-gray-200 bg-white text-gray-800 shadow-sm hover:bg-gray-50 disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-900 dark:border-neutral-700 dark:text-white dark:hover:bg-neutral-800"
                  >
                    <CalendarDaysIcon className="h-5 w-5" />
                    <span>Google Calendar</span>
                  </button>
                  <button
                    onClick={() => onOpenCalendarURL("device")}
                    type="button"
                    className="py-3 px-4 group inline-flex items-center gap-x-2 text-sm font-medium rounded-lg border border-gray-200 bg-white text-gray-800 shadow-sm hover:bg-gray-50 disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-900 dark:border-neutral-700 dark:text-white dark:hover:bg-neutral-800"
                  >
                    <LinkIcon className="h-5 w-5" />
                    <span>Default Calendar</span>
                  </button>
                </div>
              )}
            </>
          }
        />
      </SectionDetails>
    </Page>
  );
}
