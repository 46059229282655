export default function SectionDetails({
  title,
  children,
  loading,
  onSave,
  saveTitle,
  disableAction,
}: {
  title: string;
  children?: any;
  loading?: boolean;
  onSave?: Function;
  saveTitle?: string;
  disableAction?: boolean;
}) {
  const handleFormSubmit = (e: any) => {
    e.preventDefault();
    onSave && onSave();
  };

  return (
    <div className="mx-auto mb-10">
      <div className="bg-white rounded-xl shadow p-4 sm:p-7 dark:bg-slate-900">
        {loading ? (
          <div className="text-center mt-20 mb-20 pt-20 pb-20">
            <div
              className=" animate-spin inline-block size-8 border-[3px] border-current border-t-transparent text-blue-600 rounded-full dark:text-blue-500"
              role="status"
              aria-label="loading"
            >
              <span className="sr-only">Loading...</span>
            </div>
          </div>
        ) : (
          <form onSubmit={(e) => handleFormSubmit(e)}>
            {title && (
              <h2 className="mb-6 text-lg font-semibold text-gray-800 dark:text-white">
                {title}
              </h2>
            )}
            <div className="grid sm:grid-cols-12 gap-2 sm:gap-6">
              {children}
            </div>
            <div className="mt-5 flex justify-end gap-x-2">
              {onSave && (
                <button
                  disabled={disableAction}
                  type="submit"
                  className="py-2 px-3 inline-flex items-center gap-x-2 text-sm font-semibold rounded-lg border border-transparent bg-blue-600 text-white hover:bg-blue-700 disabled:opacity-50 disabled:pointer-events-none"
                >
                  {saveTitle ?? "Save changes"}
                </button>
              )}
            </div>
          </form>
        )}
      </div>
    </div>
  );
}
