import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";

import { useLocalStorage } from "../hooks/localStorage.ts";
import API from "./../helpers/api.ts";
import Page from "./../components/page";
import TableView from "../components/tableView";
import Modal from "../components/modal";
import ReadMore from "../components/readMore.tsx";

import { BuildingOffice2Icon } from "@heroicons/react/24/outline";

const sortOptions = [
  {
    id: "title_asc",
    alt_id: "title",
    title: "Title (A-z)",
    field: "title",
    dir: "asc",
  },
  {
    id: "title_desc",
    title: "Title (Z-a)",
    field: "title",
    dir: "desc",
  },
  {
    id: "created_at_desc",
    alt_id: "latest",
    title: "Latest",
    field: "created_at",
    dir: "desc",
  },
  {
    id: "created_at_asc",
    alt_id: "oldest",
    title: "Oldest",
    field: "created_at",
    dir: "asc",
  },
  {
    id: "updated_at_desc",
    alt_id: "updated",
    title: "Last update",
    field: "updated_at",
    dir: "desc",
  },
];

const tableHeadings = [
  {
    id: "title",
    title: "Title",
    type: "link",
    linkConfig: {
      baseURL: "/properties/",
      id: "id",
      cache: "_",
    },
    width: "max",
  },
  {
    id: "owner",
    nested: "name",
    title: "Owner",
    type: "link",
    linkConfig: {
      baseURL: "/clients/",
      id: "owner_id",
      cache: "owner",
    },
    width: "max",
  },
  {
    id: "tenant",
    nested: "name",
    title: "Tenant",
    type: "link",
    linkConfig: {
      baseURL: "/clients/",
      id: "tenant_id",
      cache: "tenant",
    },
    default: "None",
    width: "max",
  },
  {
    id: "address",
    title: "Address",
    type: "text",
    width: "max",
  },
];

const rowOptions = [
  {
    id: "view",
    title: "View details",
  },
];

export default function Properties() {
  const navigate = useNavigate();
  const [refresh, setRefresh] = useState<boolean>(false);
  const [searchTerm, setSearchTerm] = useState<string>("");

  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
  const [deleteDetails, setDeleteDetails] = useState<any>(null);
  const [deletingProperty, setDeletingProperty] = useState<boolean>(false);

  const [loadingProperties, setLoadingProperties] = useState<boolean>(true);
  const [properties, setProperties] = useState<any>([]);

  const [currentPage, setCurrentPage] = useState<number>(1);
  const [pagination, setPagination] = useState<any>(null);

  const [sortBy, setSortBy] = useLocalStorage(
    "prefs.properties.sort",
    "created_at"
  );
  const [sortDir, setSortDir] = useLocalStorage("prefs.properties.dir", "desc");

  useEffect(() => {
    // TODO: Move to useSWR hook
    const controller = new AbortController();
    const signal = controller.signal;

    setLoadingProperties(true);

    API.properties
      .list(currentPage, sortBy, sortDir, searchTerm, signal)
      .then((res: any) => {
        setPagination(res.pagination);
        setProperties(res.data);
        setLoadingProperties(false);
      })
      .catch(() => {
        if (!signal.aborted) toast.error("Failed to load property list");
      });

    return () => {
      controller.abort();
    };
  }, [refresh, currentPage, sortBy, sortDir, searchTerm]);

  const handleSort = (sortValue: any) => {
    setSortBy(sortValue.field);
    setSortDir(sortValue.dir);

    setCurrentPage(1);
  };

  const handleRowOption = (optionId: string, propertyDetails: any) => {
    if (optionId === "view") {
      navigate(`/properties/${propertyDetails?.id}`, {
        state: { cachedDetails: propertyDetails },
      });
    }
  };

  const handleDeleteProperty = (propertyDetails: any) => {
    setDeleteDetails(propertyDetails);
    setShowDeleteModal(true);
  };

  const onDeleteProperty = () => {
    setDeletingProperty(true);

    API.properties
      .delete(deleteDetails?.id)
      .then(() => {
        setRefresh(!refresh);
        setCurrentPage(1);
        setShowDeleteModal(false);
        setDeleteDetails(null);
        setDeletingProperty(false);
        toast.success("Property deleted successfully");
      })
      .catch(() => {
        setShowDeleteModal(false);
        setDeleteDetails(null);
        setDeletingProperty(false);
        toast.error("Failed to delete property");
      });
  };

  const handleAddNewProperty = () => {
    navigate("/properties/new");
  };

  return (
    <Page
      title="Properties"
      sort={sortOptions}
      handleSort={handleSort}
      currentSort={`${sortBy}_${sortDir}`}
      onCreate={handleAddNewProperty}
      createTxt="Add property"
      searchHint="Search for properties"
      handleSearch={setSearchTerm}
      currentSearch={searchTerm}
    >
      <Modal
        id="delete_property"
        title="Delete property"
        confirmTxt="Delete"
        confirmStyle="bg-red-500 hover:bg-red-600"
        show={showDeleteModal}
        onClose={() => setShowDeleteModal(false)}
        onConfirm={onDeleteProperty}
        loading={deletingProperty}
      >
        <p className="mt-1 text-gray-800 dark:text-gray-400">
          Are you sure you want to remove{" "}
          <span className="italic font-semibold">{deleteDetails?.title}</span>{" "}
          from your list of properties?
        </p>
        <ReadMore id="delete_property" key={deleteDetails?.id}>
          <p>Deleting this property will also delete all related viewings.</p>
        </ReadMore>
      </Modal>
      <TableView
        refreshKey={currentPage}
        headings={tableHeadings}
        loading={loadingProperties}
        data={properties}
        options={rowOptions}
        onOption={handleRowOption}
        onDelete={handleDeleteProperty}
        emptyStateIcon={<BuildingOffice2Icon className="h-20 w-20" />}
        emptyStateTitle="No properties added"
        emptyStateDesc="Add a property to get started with managing rentals and sells"
        emptyStateActionTitle="Add property"
        emptyStateOnAction={handleAddNewProperty}
        pagination={pagination}
        handlePagination={setCurrentPage}
        searchTerm={searchTerm}
        onClearSearch={() => setSearchTerm("")}
      />
    </Page>
  );
}
